import React, { useEffect, useState } from "react";
import "./Counter.scss";

const countDownDate = new Date("July 12, 2024 14:30:00").getTime();

function Counter() {
  const now = new Date().getTime();
  const distanceInitial = countDownDate - now;
  const daysInitial = Math.floor(distanceInitial / (1000 * 60 * 60 * 24));
  const hourseInitial = Math.floor(
    (distanceInitial % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesInitial = Math.floor(
    (distanceInitial % (1000 * 60 * 60)) / (1000 * 60)
  );
  const secondsInitial = Math.floor((distanceInitial % (1000 * 60)) / 1000);

  const [showText, setShowText] = useState(false);

  const [days, setDays] = useState(daysInitial > 0 ? daysInitial : 0);
  const [hours, setHours] = useState(hourseInitial > 0 ? hourseInitial : 0);
  const [minutes, setMinutes] = useState(
    minutesInitial > 0 ? minutesInitial : 0
  );
  const [seconds, setSeconds] = useState(
    secondsInitial > 0 ? secondsInitial : 0
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

      if (distance < 0) {
        clearInterval(interval);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        setShowText(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [days, hours, minutes, seconds]);

  return (
    <div className="counter">
      <div className="container">
        <div className="flex flex-justify-center flex-align-center">
          <div className="col-8 col-12-xs col-12-sm col-12-md">
            <h2 className="text-center text-bold text-white m-bottom-3 m-top-10">
              ОСТАВАТ
            </h2>
            <div className="flex flex-justify-center flex-align-center m-bottom-8">
              <div className="text-center col-3">
                <div className="text-white text-bold counter-text counter-text-shadow">
                  {days}
                </div>
                <div className="text-bold text-white text-lg">ДНИ</div>
              </div>
              <div className="text-center col-3">
                <div className="text-white text-bold counter-text counter-text-shadow">
                  {hours}
                </div>
                <div className="text-bold text-white text-lg ">ЧАСА</div>
              </div>
              <div className="text-center col-3">
                <div className="text-white text-bold counter-text counter-text-shadow">
                  {minutes}
                </div>
                <div className="text-bold text-white text-lg">МИН.</div>
              </div>
              <div className="text-center col-3">
                <div className="text-white text-bold counter-text counter-text-shadow">
                  {seconds}
                </div>
                <div className="text-bold text-white text-lg">СЕК.</div>
              </div>
            </div>
            {showText && (
              <h2 className="text-center text-bold text-white m-bottom-8">
                СВАТБА ЕЕЕ!
              </h2>
            )}
            {!showText && (
              <div className="flex flex-justify-center m-bottom-8">
                <a href="#confirm" className="confirm-btn confirm-btn-inverse">
                  <i className="fa-solid fa-calendar-check m-right-1"></i>
                  ПОТВЪРДИ <span className="hidden-xs">ПРИСЪСТВИЕ</span>
                </a>
                <div className="col-12 text-center text-white text-bold m-top-1">
                  до 10 Юни, 2024г.
                </div>
              </div>
            )}
            <div className="heart m-auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
