import React from "react";

const mapEmbeded = `<div style="width: 100%"><iframe width="100%" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=320&amp;hl=en&amp;q=+(%D0%92%D0%B8%D0%BB%D0%B0%20%D0%95%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%BD%D0%B0)&amp;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>`;
const mapEmbededMobile = `<div style="width: 100%"><iframe width="100%" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=320&amp;hl=en&amp;q=+(%D0%92%D0%B8%D0%BB%D0%B0%20%D0%95%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%BD%D0%B0)&amp;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>`;

export default function Transport() {
  const isMobile = window && window.innerWidth < 600;
  return (
    <div className="p-top-12 p-bottom-8 transport transport--bg" id="transport">
      <div className="container p-top-8">
        <h1 className="text-center m-bottom-4">Как да стигна?</h1>
        <div className="flex flex-justify-center text-center">
          <div className="col-12 col-8-xl">
            <p className="text-md m-bottom-2">
              Тук може да намерите GPS координати на мястото на нашето сватбено тържество. 
            </p>
            <p className="text-md m-bottom-2">
              Вила Екатерина се намира на 23км. от София в местност Полиовци, Вакарел. На място има достъпен безплатен паркинг.
            </p>
            <p className="text-md m-bottom-2">
              При нужда от таксита, може да използвате следните компании:
            </p>
            <p className="text-lg text-bold text-sm-xs m-top-3">
              ТАКСИ "YELLOW": тел. <span className="text-hover text-bold">{" 02 9 11 19"}</span>
            </p>
            <p className="text-lg text-bold text-sm-xs m-top-1 m-bottom-5">
              ТАКСИ "O.K. Supertrans": тел.<span className="text-hover text-bold">{" 02 973 2121"}</span>
            </p>
          </div>
        </div>
        <div className="flex flex-justify-center">
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Вила Екатерина</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="display-block m-auto p-top-3 p-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbeded }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededMobile }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
