import React from "react";
import KrajbaBulka from "./images/kradenenabulka.webp";

function KrajbaNaBulkata() {
  return (
    <div
      className="bride-steal bg-primary p-top-8 p-bottom-8"
      id="bridesteal"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-solid fa-house-lock m-right-1 text-xl" />
            <span className="text-secondary">14:30</span>
          </h2>
          <h1 className="m-bottom-1 text-page">Кражба на Булката</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={KrajbaBulka}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1">
              Младоженецът, заедно със своята дружина и роднини, ще трябва да положат доста усилия, за да открадат Булката, която чака в тайно скривалище във             
              <span className="text-bold">
                {" "}Вила Екатерина
              </span>!{" "}
              Женският отбор на Булката ще направи всичко възможно да затрудни Младоженеца! 
              <br/><br/>Дали ще успеят? Дали ще им бъде трудно? Но, на кого?
          </p>
        </div>
      </div>
    </div>
  );
}

export default KrajbaNaBulkata;
