import React from "react";
import "./Schedule.scss";
import FadeInSection from "./FadeInSection";
import RingsIcon from "./images/rings.webp";

// 13:00 - 14:00 - Кражба на булка
// 14:30 - 15:30 - Църковен брак
// 15:30 - 17:30 - Фото + пристигане в рест. 
// 16:30 - 17:30 -  Welcome drinks
// 17:30 - 18:00 -  Изнесен ритуал
// 18:00 - 19:00 - Снимки с гости
// 19:30 - Посрещане на младоженци


function Schedule() {
  return (
    <div className="bg-base">
      <div className="timeline p-top-8 p-bottom-8">
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <i className="fa-solid fa-house-lock m-right-1 text-xl" />
                <span className="text-primary">13:00</span>
              </h2>
              <h3 className="m-bottom-1">Кражба на Булката</h3>
              <p className="m-bottom-1">
              Булката ще се крие в родната си къща в с. Коларово, строго охранявана от своите сестри, зетьове и роднини.
              </p>
              <a className="see-more m-top-2 text-primary" href="#bridesteal">
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-0"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-14"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <i className="fa-solid fa-church m-right-1 text-xl" />
                <span className="text-primary">14:30</span>
              </h2>
              <h3 className="m-bottom-1">Църковен брак</h3>
              <p className="m-bottom-1">
                Църковният брак ще се състои в Храм „Свети Пророк Илия“, с. Беласица.
              </p>
              <a
                className="see-more m-top-2 text-primary"
                href="#lqskovskimanastir"
              >
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-14"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <i className="fa-solid fa-martini-glass-citrus m-right-1 text-xl"></i>
                <span className="text-primary">16:30</span>
              </h2>
              <h3 className="m-bottom-1">Welcome drink</h3>
              <p className="m-bottom-1">
                Ще се състои в градината на Хотел-ресторант Хановете, с. Ръждак.
              </p>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-16"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <img
                  alt=""
                  src={RingsIcon}
                  style={{ width: 30 }}
                  className="flex m-right-1 text-xl"
                />
                <span className="text-primary">17:30</span>
              </h2>
              <h3 className="m-bottom-1">Граждански брак</h3>
              <p className="m-bottom-1">
              Изнесеният ритуал ще се състои в градината на Хотел-ресторант Хановете, с. Ръждак. След ритуала ще имаме време да се снимаме и поздравим с всички наши гости.
              </p>
              <a
                className="see-more m-top-2 text-primary"
                href="#grajdanskibrak"
              >
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-17"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <i className="fa-brands fa-fort-awesome m-right-1 text-xl" />
                <span className="text-primary">19:30</span>
              </h2>
              <h3 className="m-bottom-1">Сватбено тържество</h3>
              <p className="m-bottom-1">
                Ресторант "Хановете", Петрич.
              </p>
              <a className="see-more m-top-2 text-primary" href="#restaurant">
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-19"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <i className="fa-solid fa-people-pulling m-right-1 text-xl" />
                <span className="text-primary">20:30</span>
              </h2>
              <h3 className="m-bottom-1">Сватбени ритуали и обичаи</h3>
              <p className="m-bottom-1">Кой ще командва у дома?</p>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-20"></div>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default Schedule;
