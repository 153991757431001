import React, { useState } from "react";
import "./Header.scss";

function Header() {
  const [open, setOpen] = useState(false);

  return (
    <nav className="navbar p-right-2-lg p-right-2-xl">
      <div className="hidden-lg hidden-xl relative">
        <div className="navbar-container-mobile flex flex-justify-end">
          <i
            className="sandwich-menu fa-solid fa-bars"
            onClick={() => setOpen(true)}
          ></i>
          {open && (
            <div
              className="sandwich-menu-content p-top-5 shadow-default"
              onClick={() => setOpen(false)}
            >
              <i
                className="sandwich-menu sandwich-menu-opened fa-solid fa-xmark"
                onClick={() => setOpen(false)}
              />
              <div>
                <a className="flex flex-align-center" href="#schedule">
                  <i className="fa-solid fa-clock m-right-1"></i>Програма
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#godparents">
                  <i className="fa-solid fa-people-carry-box m-right-1"></i>
                  Кумове
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#transport">
                  <i className="fa-solid fa-car m-right-1"></i>Транспорт
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#history">
                  <i className="fa-solid fa-book m-right-1"></i>История
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#confirm">
                  <i className="fa-solid fa-calendar-check m-right-1"></i>
                  Потвърди
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <ul className="navbar-container flex flex-justify-end hidden-xs hidden-sm hidden-md">
        <li>
          <a className="flex flex-align-center" href="#schedule">
            <i className="fa-solid fa-clock m-right-1"></i>Програма
          </a>
        </li>
        <li>
          <a className="flex flex-align-center" href="#godparents">
            <i className="fa-solid fa-people-carry-box m-right-1"></i>Кумове
          </a>
        </li>
        <li>
          <a className="flex flex-align-center" href="#transport">
            <i className="fa-solid fa-car m-right-1"></i>Транспорт
          </a>
        </li>
        <li>
          <a className="flex flex-align-center" href="#history">
            <i className="fa-solid fa-book m-right-1"></i>История
          </a>
        </li>
        <li>
          <a className="flex flex-align-center" href="#confirm">
            <i className="fa-solid fa-calendar-check m-right-1"></i>Потвърди
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
