import React from "react";
import BulgarianRoses from "./images/bulgarian-roses.webp";

function Questions() {
  return (
    <div className="bg-page">
      <section className="container text-center p-top-8 p-bottom-8">
        <div className="flex flex-justify-center">
          <h1 className="col-12 text-center">12 Юли, 2024г.</h1>
          <div className="col-5 col-8-xs">
            <img
              style={{ width: "100%" }}
              src={BulgarianRoses}
              alt="Bulgarian Roses"
            ></img>
          </div>
          <div className="col-12-xs col-12-sm col-8">
            <h2 className="m-bottom-2 text-primary">
              С кого да се свържа в деня на Сватбата?
            </h2>
            <div className="text-lg p-right-10-xl p-left-10-xl">
            При възникнали въпроси от всякакво естество в деня на сватбата можете да се свържете с нашия страхотен сватбен координатор:{" "}
            </div>
            <p className="m-top-2 m-bottom-2 text-lg m-bottom-8">
              Валентина - тел.{" "}
              <span className="text-bold text-hover">088 777 1756</span>
            </p>
            <h2 className="m-bottom-2 text-primary">Цветя</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-8">
              За букети не мислете и цветята неоткъснати оставете. Вместо тях, със скромна сума по Ваш избор, благотворителната ни кауза подкрепете!
            </div>
            <h2 className="m-bottom-2 text-primary">Подаръци</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-4">
              А подаръците нека да са в брой, че посуда вкъщи - рой! 
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questions;
