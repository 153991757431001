import React, { useEffect, useState } from "react";
import "./FoldBox.scss";
import classnames from "classnames";

export default function Fold() {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 768) {
      return;
    }

    const timer = setTimeout(() => {
      setActive(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (window && window.innerWidth < 768) {
      return;
    }

    const interval = setInterval(() => {
      setActive(!active);
    }, 4000);
    return () => clearInterval(interval);
  }, [active]);

  return (
    <div className="flex flex-justify-center p-top-8-xs">
      <div className={classnames("box", { "box-active": active })}>
        <span className="rotated hidden-xs"></span>
        <div className="text-center m-top-3">
          <h4 className="text-nie">Ние,</h4>
          <h1>Гиздава</h1>
          <h2>&#38;</h2>
          <h1 className="custom-margin-danail">Данаил</h1>
        </div>
      </div>
    </div>
  );
}
