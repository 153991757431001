import React from "react";

const mapEmbededChurch = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=Saint%20Ilia%20Church&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileChurch = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=Saint%20Ilia%20Church&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;
const mapEmbededRestaurant = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%A5%D0%BE%D1%82%D0%B5%D0%BB-%D1%80%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD%D1%82%20%D0%A5%D0%B0%D0%BD%D0%BE%D0%B2%D0%B5%D1%82%D0%B5&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileRestaurant = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%A5%D0%BE%D1%82%D0%B5%D0%BB-%D1%80%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD%D1%82%20%D0%A5%D0%B0%D0%BD%D0%BE%D0%B2%D0%B5%D1%82%D0%B5&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;
const mapEmbededHouseBride = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=41%C2%B021'50.9%22N%2023%C2%B006'44.2%22E&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileHouseBride = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=41%C2%B021'50.9%22N%2023%C2%B006'44.2%22E&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;
const mapEmbededHouseGroom = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%95%D0%9A%D0%9E%20%D0%A5%D0%9E%D0%A2%D0%95%D0%9B%20%D0%9C%D0%9E%D0%9C%D0%90%D0%A2%D0%90&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileHouseGroom = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%95%D0%9A%D0%9E%20%D0%A5%D0%9E%D0%A2%D0%95%D0%9B%20%D0%9C%D0%9E%D0%9C%D0%90%D0%A2%D0%90&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/124/">spider man 3 123movies</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;

export default function Transport() {
  //TODO
  const isMobile = window && window.innerWidth < 600;
  return (
    <div className="p-top-12 p-bottom-8 transport transport--bg" id="transport">
      <div className="container p-top-8">
        <h1 className="text-center m-bottom-4">Как да стигна?</h1>
        <div className="flex flex-justify-center">
          <div className="col-12 col-8-xl">
            <p className="text-md m-bottom-2">
              Тук може да намерите GPS координатите на всички места от Сватбата.
            </p>
            <p className="text-md m-bottom-2">
            Нашият съвет е да сте с кола до ресторанта, като там има голям паркинг.
            Можете спокойно да си хапнете и пийнете, а след това да се приберете с такси. 

            </p>
            <p className="text-md m-bottom-2">
            Ще има извикани таксита пред ресторанта, които ще бъдат на разположение, а ако са заети или ги няма в момента, може да си поръчате на следните телефони:
            </p>
            <p className="text-lg text-bold m-top-3 m-bottom-7">
              <span className="text-hover">ТАКСИ</span> “Илко-Такси”: тел.{" "}
              <span className="text-hover">0897 520 550</span> или тел.{" "}
              <span className="text-hover">0876 353 583</span>
            </p>
          </div>
        </div>
        <div className="flex flex-justify-center">
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Храм „Свети Пророк Илия“</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededChurch }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededMobileChurch }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Хотел-ресторант Хановете</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededRestaurant }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededMobileRestaurant,
                  }}
                />
              )}
            </div>
          </div>

          <div className="col-12 col-6-xl">
            <h2 className="text-center">Къща на Булката</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededHouseBride }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededMobileHouseBride,
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Къща на Младоженеца</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededHouseGroom }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededMobileHouseGroom,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
