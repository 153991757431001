import React from "react";
import KrajbaBulka from "./images/kradenenabulka.webp";

function KrajbaNaBulkata() {
  return (
    <div
      className="bride-steal bride-steal--bg p-top-8 p-bottom-8"
      id="bridesteal"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-solid fa-house-lock m-right-1 text-xl" />
            <span className="text-primary">14:00</span>
          </h2>
          <h1 className="m-bottom-1">Кражба на Булката</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={KrajbaBulka}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1">
            Младоженецът, с неговата чета и роднини, ще се съберат в 14:00ч. в
            двора на{" "}
            <span className="text-primary text-bold">
              Къща "При Чакъра" - Арбанаси
            </span>
            , oткъдето под ритъма на тъпана и гайдата ще се запътят победоносно
            към къщата на Булката. "Тайното" скривалище на Булката ще бъде{" "}
            <span>къща "Дебора", Арбанаси</span> (на не повече от 200м. пеша).
            Дали ще има сватба?
            <br /> И дали ще успеят да откраднат булката? ...
          </p>
        </div>
      </div>
    </div>
  );
}

export default KrajbaNaBulkata;
