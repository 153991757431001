import React from "react";
import "./Godparents.scss";
import kumoveImage from "./images/kumove.webp";

function Godparents() {
  return (
    <div className="godparents" id="godparents">
      <div className="container p-top-8">
        <h1 className="text-center text-secondary m-bottom-1">КУМОВЕ</h1>
        <div className="flex flex-justify-center"><h3 className="text-center text-white col-8 col-11-md col-12-xs col-12-sm">
        На сем. Якимови, задача отговорна отредихме - освен приятели, наши Kумове да са решихме!<i className="m-left-2 fa-regular fa-heart text-lg"></i>
        </h3></div>
        <div className="flex flex-justify-center">
          <div className="col-6 col-12-xs col-10-sm">
            <div className="flex flex-justify-center">
              <div className="godparents-photo-container relative">
                <img
                  alt=""
                  src={kumoveImage}
                  className="godparents-photo"
                ></img>
                <h2 className="evelin-text text">Дарина</h2>
                <h2 className="nikolai-text text">Ярослав</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Godparents;

/**

 <div>
        <div className="container p-top-6">
            <h1 className="text-center text-primary">КУМОВЕ</h1>
            <div className="flex flex-justify-center">
              <div className="col-8 col-12-xs col-6-sm col-6-sm">
                <div className="flex flex-justify-center">
                  <div className="col-6 col-xs-12 text-center relative">
                    <div className="photo-rounded eva"><img alt="" src={Eva} className="godparent-photo"></img></div>
                    <div className="godparents-text">
                      <h3 className="text-primary">Евелин</h3>
                      <h6>Главатар на отбор Булка</h6>
                    </div>
                    <h1 className="godparents-and text-primary">&amp;</h1>
                  </div>
                  <div className="col-6 col-xs-12 text-center">
                  <div className="photo-rounded nikicha"><img alt="" src={Nikicha} className="godparent-photo"></img></div>
                    <div className="godparents-text">
                    <h3 className="text-primary">Николай</h3>
                    <h6>Главен Командир на отбор Младоженец</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>


 */
