import React from "react";
import LqskovskiManastirImg from "./images/lqskovskimanastir.webp";
import SimpleImageSlider from "react-simple-image-slider";

import church1 from "./images/church1.webp";
import church2 from "./images/church2.webp";
import church3 from "./images/church3.webp";
import church4 from "./images/church4.webp";

import BulgarianRose from "./images/bulgarian-rose.webp";

const images = [
  { url: church1 },
  { url: church2 },
  { url: church3 },
  { url: church4 },
];

function LqskovskiManastir() {
  return (
    <div
      className="church church--bg p-top-8 p-bottom-8"
      id="lqskovskimanastir"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-solid fa-church m-right-1 text-xl" />
            <span className="text-third">14:30</span>
          </h2>
          <h1 className="m-bottom-1">Църковен брак</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={LqskovskiManastirImg}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl m-auto p-top-2 ">
          <p className="m-bottom-4 text-center">
            Църковният брак ще се състои в{" "}
            <span className="text-third text-bold">
              Храм „Свети Пророк Илия“, с. Беласица
            </span> {" "}
            (на 3 км от с. Коларово; 5 минути с кола). Църквата е малка и силно енергийна. Има много красив двор и живописна гледка към планините Пирин и Огражден.
          </p>
        </div>
        <div className="slider m-auto p-top-4 hidden-xs hidden-sm">
          <SimpleImageSlider
            width={420}
            height={600}
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
            navMargin={8}
          />
          <span
            className="m-auto display-block relative"
            style={{ width: 420, height: 0 }}
          >
            <div className="bulgarian-rose">
              <img
                style={{ width: "100%" }}
                src={BulgarianRose}
                alt="Gizi and Dido"
              ></img>
            </div>
          </span>
        </div>
        <div className="slider m-auto p-top-4 hidden-md hidden-lg hidden-xl">
          <SimpleImageSlider
            width={288}
            height={411}
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
            navMargin={8}
          />
          <span
            className="m-auto display-block relative"
            style={{ width: 288, height: 0 }}
          >
            <div className="bulgarian-rose">
              <img
                style={{ width: "100%" }}
                src={BulgarianRose}
                alt="Gizi and Dido"
              ></img>
            </div>
          </span>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl m-auto p-top-4">
          <p className="m-bottom-3 text-center">
            Интересен факт: Булката е кръстена тук и двамата с младоженеца одобряват виното <i className="m-left-0 fa-solid fa-wine-glass"></i>, което отецът дава.<i className="fa-regular fa-face-laugh-beam m-left-0"></i>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LqskovskiManastir;
