import React from "react";
import "./Fold.scss";
import leftFoldImage from "./images/left-fold.webp";
import rightFoldImage from "./images/right-fold.webp";
import leftDownFoldImage from "./images/left-down.png";

function Fold() {
  return (
    <div className="relative p-top-12 p-bottom-12 p-top-6-xs p-bottom-6-xs">
        <img
          className="bg-fold-left"
          src={leftFoldImage}
          alt=""
        ></img>
        <img
          className="bg-fold-right"
          src={rightFoldImage}
          alt=""
        ></img>
        <img
          className="bg-fold-left-down"
          src={leftDownFoldImage}
          alt=""
        ></img>
      <div className="relative container p-top-11-xs p-top-11-sm">
        <div className="text-center p-top-4 p-top-no-xs p-top-no-sm">
            <h3 className="text-primary">Ние,</h3>
            <h1>Десислава &#38; Виктор</h1>
        </div>
        <h4 className="font-heading text-center p-bottom-4 m-top-4">
          С благословията на нашите скъпи родители, <br className="hidden-xs" />
          с радост Ви каним да споделите с нас Деня,{" "}
          <br className="hidden-xs" />в който ще се наречем{" "}
          <span className="text-primary text-bold">Съпруг</span>
          {" "} и {" "}
          <span className="text-third text-bold">Съпруга</span>!
        </h4>
        <h1 className="text-center p-bottom-2-xs m-bottom-12">12 Юли, 2024г.</h1>
      </div>
      <div className="flex">
        <div
          className="col-12"
        ></div>
      </div>
    </div>
  );
}

export default Fold;
