import React from "react";
import "./Schedule.scss";
import FadeInSection from "./FadeInSection";
import RingsIcon from "./images/rings.webp";
import Genevi from "./images/genevi.webp";
import Mladenovi from "./images/mladenovi.webp";

function History() {
  return (
    <div className="bg-base">
      <div className="timeline p-top-8 p-bottom-8">
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <div>
                <img alt="" src={Genevi} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">14 Ноември, 1989г.</span>
              </h2>
              <h3 className="m-bottom-1">сем. Геневи</h3>
              <p className="m-bottom-1">Сватбата на Диана и Валери</p>
            </div>
            <div className="timeline-circle"></div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">10 Юни, 1990г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се бебе Григор
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">виновници: сем. Геневи</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <div>
                <img alt="" src={Mladenovi} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">10 Ноември, 1993г.</span>
              </h2>
              <h3 className="m-bottom-1">сем. Младенови</h3>
              <p className="m-bottom-1">Сватбата на Мариана и Юлиян</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">07 Април, 1993г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се бебе Йорданка
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">виновници: сем. Младенови</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">29 Септември, 1995г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се бебе Гиздава
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">виновници: сем. Геневи</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">11 Ноември, 1997г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се бебе Данаил
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">виновници: сем. Младенови</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">Септември, 2016г.</span>
              </h2>
              <h3 className="m-bottom-1">Запознанство</h3>
              <p className="m-bottom-1">
                Дидо и Гизи се срещнаха за първи път на Летните Университетски
                игри 2016г. в Камчия, България. И двамата представители на
                ТУ-София, Дидо - състезател по волейбол, Гизи - състезател по
                плуване. Заедно участваха в спечелването на Купата на
                Университетския шампионат <i className="fa-solid fa-trophy"></i>
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">29 Септември, 2021г.</span>
              </h2>
              <h3 className="m-bottom-1 flex flex-align-center">
                <span>Тя каза "Разбира се"!</span>
                <div className="flex flex-align-center">
                  <img
                    alt=""
                    src={RingsIcon}
                    style={{ width: 30 }}
                    className="m-left-2"
                  />
                </div>
              </h3>
              <p className="m-bottom-1">виновник: Дидо</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">27 Август, 2022г.</span>
              </h2>
              <h3 className="m-bottom-1">Сватба - сем. Младенови</h3>
              <p className="m-bottom-1">
                И нека продължим тази история заедно! Бъдете с нас в Денят, в
                който сем. Геневи и сем. Младенови ще се слеят в едно!{" "}
              </p>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default History;
