import React from "react";
import "./Godparents.scss";
import NikichaAndEva from "./images/evaandnikicha.webp";

function Godparents() {
  return (
    <div className="godparents" id="godparents">
      <div className="container p-top-6">
        <h1 className="text-center text-hover m-bottom-1">КУМОВЕ</h1>
        <h5 className="text-center hidden-xs hidden-sm">
          Сред голяма конкуренция, кумовете ний избрахме и до живот ги ...
        </h5>
        <h5 className="text-center hidden-xs hidden-sm">с любов дарихме :)</h5>
        <h4 className="text-center hidden-md hidden-lg hidden-xl">
          Сред голяма конкуренция, кумовете ний избрахме и до живот ги ...
        </h4>
        <h4 className="text-center hidden-md hidden-lg hidden-xl">
          с любов дарихме :)
        </h4>
        <div className="flex flex-justify-center">
          <div className="col-6 col-12-xs col-6-sm col-6-sm">
            <div className="flex flex-justify-center">
              <div className="godparents-photo-container relative">
                <img
                  alt=""
                  src={NikichaAndEva}
                  className="godparents-photo"
                ></img>
                <h2 className="evelin-text text">Евелин</h2>
                <h2 className="nikolai-text text">Николай</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Godparents;

/**

 <div>
        <div className="container p-top-6">
            <h1 className="text-center text-primary">КУМОВЕ</h1>
            <div className="flex flex-justify-center">
              <div className="col-8 col-12-xs col-6-sm col-6-sm">
                <div className="flex flex-justify-center">
                  <div className="col-6 col-xs-12 text-center relative">
                    <div className="photo-rounded eva"><img alt="" src={Eva} className="godparent-photo"></img></div>
                    <div className="godparents-text">
                      <h3 className="text-primary">Евелин</h3>
                      <h6>Главатар на отбор Булка</h6>
                    </div>
                    <h1 className="godparents-and text-primary">&amp;</h1>
                  </div>
                  <div className="col-6 col-xs-12 text-center">
                  <div className="photo-rounded nikicha"><img alt="" src={Nikicha} className="godparent-photo"></img></div>
                    <div className="godparents-text">
                    <h3 className="text-primary">Николай</h3>
                    <h6>Главен Командир на отбор Младоженец</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>


 */
