import React from "react";
import GrajdanskiBrakImage from "./images/garden.webp";
import RingsIcon from "./images/rings.webp";

function GrajdanskiBrak() {
  return (
    <div className="garden garden--bg p-top-8 p-bottom-8" id="grajdanskibrak">
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <img
              alt=""
              src={RingsIcon}
              style={{ width: 30 }}
              className="flex m-right-1 text-xl"
            />
            <span className="text-primary">17:30</span>
          </h2>
          <h1 className="m-bottom-1">Граждански брак</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={GrajdanskiBrakImage}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-top-2">
            Изнесеният ритуал ще се състои в градината на <span className="text-primary text-bold">Хотел-ресторант "Хановете",
              с. Ръждак</span> {" "}(на изхода на гр. Петрич). Ще бъде съпроводен от DJ, напитки и кетъринг за освежаване. След ритуала ще имаме време да се снимаме и поздравим с всички наши гости.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GrajdanskiBrak;
