import React from "react";
import LqskovskiManastirImg from "./images/lqskovskimanastir.webp";

function LqskovskiManastir() {
  return (
    <div
      className="church bg-white p-top-8 p-bottom-8"
      id="lqskovskimanastir"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-solid fa-church m-right-1 text-xl" />
            <span className="text-primary">17:45</span>
          </h2>
          <h1 className="m-bottom-1">Църковен брак</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={LqskovskiManastirImg}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl m-auto p-top-2 ">
          <p className="m-bottom-4 text-center">
            Църковният брак ще се състои в Параклисa в двора на{" "}
            <span className="text-hover text-bold">
            "Вила Екатерина"
            </span>
            . След приключване на църковния брак, всички гости ще имат възможността да поздравят Младоженците и да се снимат с тях за спомен.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LqskovskiManastir;
