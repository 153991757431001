import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/main.scss";
import Header from "./components/Header";
import Fold from "./components/Fold";
import Content from "./components/Content";
import Header2 from "./components/rozi-and-moni/Header";
import Fold2 from "./components/rozi-and-moni/Fold";
import Content2 from "./components/rozi-and-moni/Content";
import Header3 from "./components/desi-and-viktor/Header";
import Fold3 from "./components/desi-and-viktor/Fold";
import Content3 from "./components/desi-and-viktor/Content";

function App() {
  return (
    <div className="MyWedding">
      <Router>
        <Routes>
          {/* Add your routes here */}
          <Route path="/" element={<GiziAndDido />} />
          <Route path="/gizi-and-dido" element={<GiziAndDido />} />
          <Route path="/rozi-and-moni" element={<RoziAndMoni />} />
          <Route path="/desi-and-viktor" element={<DesiAndViktor />} />
        </Routes>
      </Router>
    </div>
  );
}

// GiziAndDido component for the "/gizi-and-dido" path
function GiziAndDido() {
  return (
    <div id="gizi-and-dido">
      <div className="bg-fold">
        <Header />
        <Fold />
      </div>
      <Content />
    </div>
  );
}

function RoziAndMoni() {
  // Use useEffect to update CSS variables when the component mounts
  useEffect(() => {
    applyColorVariables(COLOR_VARIABLES_ROZI_AND_MONI);
  }, []);

  return (
    <div id="rozi-and-moni">
      {/* <div className="flex" style={{height: 100}}>
          <div className="col" style={{background: "#5375a3", height: 100}}>primary, link</div>
          <div className="col" style={{background: "#C8AAAF", height: 100}}>secondary</div>
          <div className="col" style={{background: "#587555", height: 100}}>third</div>
          <div className="col" style={{background: "#ede5e4", height: 100}}>base</div>
          <div className="col" style={{background: "#AABFA6", height: 100}}>--border-color</div>
          <div className="col" style={{background: "#A7B8CE", height: 100}}></div>
          <div className="col" style={{background: "#9CA7A1", height: 100}}></div>
          <div className="col" style={{background: "#252D52", height: 100}}></div>
      </div> */}
      <div className="bg-fold-gradient">
        <Header2 />
        <Fold2 />
      </div>
      <Content2 />
    </div>
  );
}

const COLOR_VARIABLES_ROZI_AND_MONI = {
  "--border-color": "#AABFA6",
  "--primary-color": "#5375a3",
  "--secondary-color": "#C8AAAF",
  "--third-color": "#587555",
  "--hover-color": "#A7B8CE",
  "--link-color": "#5375a3",
  "--text-color": "#384347"
};


function DesiAndViktor() {
  // Use useEffect to update CSS variables when the component mounts
  useEffect(() => {
    applyColorVariables(COLOR_VARIABLES_DESI_AND_VIKTOR);
  }, []);

  return (
    <div id="desi-and-viktor">
      <div className="bg-fold">
        <Header3 />
        <Fold3 />
      </div>
      {/* <div className="flex" style={{height: 100}}>
          <div className="col" style={{background: "#38807Fff", height: 100}}>--border-color</div>
          <div className="col" style={{background: "#38807Fff", height: 100}}>primary, link</div>
          <div className="col" style={{background: "#C8E2F1ff", height: 100}}>secondary</div>
          <div className="col" style={{background: "#F1D6D6", height: 100}}>third</div>
          <div className="col" style={{background: "#ede5e4", height: 100}}>base</div>
          <div className="col" style={{background: "#A7B8CE", height: 100}}>hover</div>
          <div className="col" style={{background: "#38807Fff", height: 100}}>link color</div>
      </div> */}
      <Content3 />
    </div>
  );
}

const COLOR_VARIABLES_DESI_AND_VIKTOR = {
  "--border-color": "#38807Fff",
  "--primary-color": "#38807Fff",
  "--secondary-color": "#C8E2F1ff",
  "--third-color": "#F1D6D6",
  "--hover-color": "#A7B8CE",
  "--link-color": "#38807Fff",
};

// Function to apply variables to the document
function applyColorVariables(colorVariables) {
  Object.entries(colorVariables).forEach(([variable, value]) => {
    document.documentElement.style.setProperty(variable, value);
  });
}


export default App;
