import React from "react";
import "./ParallaxPhoto.scss";

function ParallaxPhoto({ photoClass, children }) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <section ref={domRef} className={`parallax ${isVisible ? photoClass : ""}`}>
      <div className="parallax-inner">{children}</div>
    </section>
  );
}

export default ParallaxPhoto;
