import React from "react";

const mapEmbededChurch = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%BB%D1%8F%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%B8%20%D0%BC%D0%B0%D0%BD%D0%B0%D1%81%D1%82%D0%B8%D1%80&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2piratebay.org">pirate bay</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileChurch = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%9B%D1%8F%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%B8%20%D0%BC%D0%B0%D0%BD%D0%B0%D1%81%D1%82%D0%B8%D1%80&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2piratebay.org">pirate bay</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;
const mapEmbededRestaurant = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%BA%D0%B0%D0%BB%D0%BE%D1%8F%D0%BD%D0%BE%D0%B2%D0%B0%20%D0%BA%D1%80%D0%B5%D0%BF%D0%BE%D1%81%D1%82&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2piratebay.org">pirate bay</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileRestaurant = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=%D0%BA%D0%B0%D0%BB%D0%BE%D1%8F%D0%BD%D0%BE%D0%B2%D0%B0%20%D0%BA%D1%80%D0%B5%D0%BF%D0%BE%D1%81%D1%82&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2piratebay.org">pirate bay</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;
const mapEmbededHouseBride = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=Vila%20Diabora,%20arbanasi&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org"></a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net">embedgooglemap.net</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileHouseBride = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=Vila%20Diabora,%20arbanasi&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org"></a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net">embedgooglemap.net</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;
const mapEmbededHouseGroom = `<div class="mapouter"><div class="gmap_canvas"><iframe width="520" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=pri%20chakura,%20arbanasi&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2piratebay.org">pirate bay</a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:520px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:520px;}</style></div></div>`;
const mapEmbededMobileHouseGroom = `<div class="mapouter"><div class="gmap_canvas"><iframe width="310" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=Hotel%20Pri%20Chakara&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org"></a><br><style>.mapouter{position:relative;text-align:right;height:320px;width:310px;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important;height:320px;width:310px;}</style></div></div>`;

export default function Transport() {
  //TODO
  const isMobile = window && window.innerWidth < 600;
  return (
    <div className="p-top-12 p-bottom-8 transport transport--bg" id="transport">
      <div className="container p-top-8">
        <h1 className="text-center m-bottom-4">Как да стигна?</h1>
        <div className="flex flex-justify-center">
          <div className="col-12 col-8-xl">
            <p className="text-md m-bottom-2">
              Тук може да намерите GPS координатите на всички места от Сватбата.
            </p>
            <p className="text-md m-bottom-2">
              Нашият съвет е да сте с кола до ресторанта, а оттам вече може да
              си оставите колата на паркинга за през нощта, да пийнете по една,
              две... три домашни ракии и да се приберете спокойно с такси от
              ресторанта.
            </p>
            <p className="text-md m-bottom-2">
              Ще има извикани таксита пред Калояновата крепост от нас, а ако са
              заети или ги няма в момента, може да си поръчате по телефон.
              Такситата от Велико Търново, пътуват до Арбанаси, Калояновата
              крепост и обратно и са на цени в посока м/у 5-10 лв.
            </p>
            <p className="text-lg text-bold m-top-3 m-bottom-3">
              <span className="text-hover">ТАКСИ</span> “СИТИ ИНВЕСТ”: тел.{" "}
              <span className="text-hover">0878 107 107</span> или тел.{" "}
              <span className="text-hover">0886 107 107</span>
            </p>
            <p className="text-md m-bottom-8">
              Моля, свържете се с нас, ако някой от вас няма транспорт и си
              търси. Ще има достатъчно гости с коли и хора, пътуващи от София до
              Велико Търново.
            </p>
          </div>
        </div>
        <div className="flex flex-justify-center">
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Лясковски манастир</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededChurch }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededMobileChurch }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Калоянова крепост</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededRestaurant }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededMobileRestaurant,
                  }}
                />
              )}
            </div>
          </div>

          <div className="col-12 col-6-xl">
            <h2 className="text-center">Къща на Булката</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededHouseBride }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededMobileHouseBride,
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Къща на Младоженеца</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededHouseGroom }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededMobileHouseGroom,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
