import React from "react";
import "./Godparents.scss";
import KumovePhoto from "./images/final-kumove-original.webp";

function Godparents() {
  return (
    <div className="godparents" id="godparents">
      <div className="container p-top-6">
        <h1 className="text-center text-hover m-bottom-1">КУМОВЕ</h1>
        <div className="flex flex-justify-center"><h5 className="col-6 col-12-xs col-12-sm text-center">
         С непоколебимо възхищение и безгранично уважение, избрахме тези изключителни хора да бъдат нашите кумове и част от нашето семейство. <i className="m-left-0 fa-regular fa-heart"></i>
        </h5></div>
        <div className="flex flex-justify-center m-top-2">
          <div className="col-6 col-12-xs col-10-sm col-6-sm">
            <div className="flex flex-justify-center">
              <div className="godparents-photo-container relative p-top-3">
                <img
                  alt=""
                  src={KumovePhoto}
                  className="godparents-photo"
                ></img>
                <h2 className="evelin-text text">Бояна</h2>
                <h2 className="nikolai-text text">Мирослав</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Godparents;

/**

 <div>
        <div className="container p-top-6">
            <h1 className="text-center text-primary">КУМОВЕ</h1>
            <div className="flex flex-justify-center">
              <div className="col-8 col-12-xs col-6-sm col-6-sm">
                <div className="flex flex-justify-center">
                  <div className="col-6 col-xs-12 text-center relative">
                    <div className="photo-rounded eva"><img alt="" src={Eva} className="godparent-photo"></img></div>
                    <div className="godparents-text">
                      <h3 className="text-primary">Евелин</h3>
                      <h6>Главатар на отбор Булка</h6>
                    </div>
                    <h1 className="godparents-and text-primary">&amp;</h1>
                  </div>
                  <div className="col-6 col-xs-12 text-center">
                  <div className="photo-rounded nikicha"><img alt="" src={Nikicha} className="godparent-photo"></img></div>
                    <div className="godparents-text">
                    <h3 className="text-primary">Николай</h3>
                    <h6>Главен Командир на отбор Младоженец</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>


 */
