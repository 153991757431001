import React, { useState } from "react";
import classnames from "classnames";

function InputForm({ onChange, id, type, text, isRequired }) {
  const [isFloat, setIsFloat] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [currentValue, setCurrentValue] = useState("");

  function setOnFocus() {
    if (!isFloat || !isFocus) {
      setIsFloat(true);
      setIsFocus(true);
    }
  }

  function setOnBlur(value) {
    setIsFocus(false);

    if (!value) {
      setIsFloat(false);
    }
  }

  function handleOnChange(value) {
    setCurrentValue(value);
    onChange(value);
  }

  return (
    <fieldset
      className={classnames("float-label-field", {
        float: isFloat || currentValue,
        focus: isFocus,
      })}
    >
      <label htmlFor={id}>{text}</label>
      <input
        maxLength="40"
        required={Boolean(isRequired)}
        id={id}
        type={type}
        value={currentValue}
        onFocus={() => setOnFocus()}
        onBlur={(e) => setOnBlur(e.target.value)}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    </fieldset>
  );
}

export default InputForm;
