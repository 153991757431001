import React from "react";
import "./Fold.scss";

import FoldMainImage from "./images/final-main-fold.webp";

function Fold() {
  return (
    <div>
      <div className="relative container p-top-11-xs p-top-11-sm">
        <div className="text-center p-top-4 p-top-no-xs p-top-no-sm">
            <h3 className="text-primary">Ние,</h3>
            <h1>Розалина &#38; Мануел</h1>
        </div>
        <h4 className="font-heading text-center p-bottom-4 m-top-4">
          С благословията на нашите скъпи родители, <br className="hidden-xs" />
          с радост Ви каним да споделите с нас Денят,{" "}
          <br className="hidden-xs" />в който ще се наречем{" "}
          <span className="text-primary text-bold">Съпруг</span>
          {" "} и {" "}
          <span className="text-secondary text-bold">Съпруга</span>!
        </h4>
        <h1 className="text-center p-bottom-2-xs">07 Юли, 2024г.</h1>
      </div>
      <img
        className="display-block fold-image-container"        
        src={FoldMainImage}
        alt="Kolarovo - Varna"
      ></img>
      {/* <div className="flex">
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#F0EBEC" }}
        ></div>
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#452B39" }}
        ></div>
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#A24F5C" }}
        ></div>
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#C48B67" }}
        ></div>
      </div> */}
    </div>
  );
}

export default Fold;
