import React from "react";
import BulgarianRoses from "./images/bulgarian-roses.webp";

function Questions() {
  return (
    <div className="bg-page">
      <section className="container text-center p-top-8 p-bottom-8">
        <div className="flex flex-justify-center">
          <div className="col-5 col-8-xs">
            <img
              style={{ width: "100%" }}
              src={BulgarianRoses}
              alt="Bulgarian Roses"
            ></img>
          </div>
          <div className="col-12-xs col-12-sm col-8">
            <h2 className="m-bottom-2 text-primary">
              С кого да се свържа в деня на Сватбата?
            </h2>
            <div className="text-lg p-right-10-xl p-left-10-xl">
            При възникнали въпроси от всякакво естество в деня на сватбата можете да се свържете със средната сестра на Булката: {" "}
            </div>
            <p className="m-top-2 m-bottom-2 text-lg m-bottom-8">
            <i className="fa-solid fa-phone m-right-1"></i>Петрана: тел.{" 0896 357 195"}
            </p>
            <h2 className="m-bottom-2 text-primary">Цветя и Подаръци</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-8">
              За подаръци и цветя не мислете, присъствието Ви на нашата сватба е най-добрият подарък, който можем да си пожелаем. Ако все пак желаете да допринесете за нашия меден месец, ще имаме кутия за подаръци.
            </div>
            <h2 className="m-bottom-2 text-primary">Нощувка</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-4 text-left">
              Препоръчваме ви да разгледате вариант с настаняване в следните хотели:
              <p className="m-top-2 m-bottom-2 text-lg text-left">1. Еко-Хотел "Момата", с. Коларово (където ще е партито преди и след сватбата) тел: 0882216414, цените за гостите на сватбата за 1 нощувка, ще бъдат по 50лв./възрастен, а за деца до 4г. безплатно (без легло), до 10г. - 25 лв./дете (вкл.закуска).
</p><p className="m-top-2 m-bottom-2 text-lg text-left">2. Комплекс "Света Неделя", с. Коларово, тел: 0877879955, Цените за нощувка са: 2-на стая - 70лв., 3-ка стая - 80 лв., апартамент 100 лв. (4 възрастни). </p>
            </div>
            <div className="text-lg p-right-10-xl p-left-10-xl m-top-6 m-bottom-8 text-left">
              За допълнителни въпроси и съдействие можете да ни потърсите на телефони:{" "}
              <p className="m-top-2 m-bottom-2 text-lg m-bottom-8">
            <i className="fa-solid fa-phone m-right-1"></i>Mони: тел.{" 0894 444 144"}, Рози: тел.{" 0896 897 358"}
            </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questions;
