import React from "react";
import "./Content.scss";
import EngagedSection from "./EngagedSection";
import DidoAndGizi from "./images/final-main-compressed.webp";
import DidoAndGiziMobile from "./images/didogizifinal-mobile.webp";
import ParallaxPhoto from "./ParallaxPhoto";
import Schedule from "./Schedule";
import KrajbaNaBulkata from "./KrajbaNaBulkata";
import LqskovskiManastir from "./LqskovskiManastir";
import Transport from "./Transport";
import GrajdanskiBrak from "./GrajdanskiBrak";
import Restaurant from "./Restaurant";
import History from "./History";
import Godparents from "./Godparents";
import Counter from "./Counter";
import Questions from "./Questions";
import ConfirmationContainer from "./ConfirmationContainer";
import EngagedSectionSlider from "./EngagedSectionSlider";

// import bgParralax1 from "./images/bg-main-1.webp";
// import bgParralax2 from "./images/bg-main-2.webp";
// import bgParralax3 from "./images/bg-main-3.webp";

function Content() {
  // useEffect(() => {
  //   const preloadImages = [DidoAndGizi, bgParralax1, bgParralax2, bgParralax3];
  //   preloadImages.forEach((imageSrc) => {
  //     const image = new Image();
  //     image.src = imageSrc;
  //   });
  // }, []);

  return (
    <div className="relative">
      <div className="bg-blue-light">
        <div className="container">
          <div className="flex">
            <div className="col-3">
              <div className="our-image-container">
                <img
                  className="our-image hidden-xs hidden-sm p-top-2 p-bottom-2 m-right-1-sm m-right-1-md"
                  src={DidoAndGizi}
                  alt="Gizi and Dido"
                ></img>
                <img
                  className="our-image hidden-md hidden-lg hidden-xl m-right-1-sm m-right-1-md"
                  src={DidoAndGiziMobile}
                  alt="Gizi and Dido"
                ></img>
              </div>
            </div>
            <div className="col-9 p-top-10 p-top-6-sm p-top-6-xs p-bottom-10 p-bottom-no-xs p-bottom-no-sm">
              <EngagedSection />
            </div>
            <div className="slider hidden-md hidden-lg hidden-xl col-auto m-auto p-bottom-6 slider-mobile p-right-2-xs p-left-2-xs">
              <EngagedSectionSlider width={288} height={411} />
            </div>
          </div>
        </div>
      </div>
      <ParallaxPhoto photoClass="parallax-engaged">
        <h1 id="schedule" className="text-white text-center">
          ПРОГРАМА
        </h1>
      </ParallaxPhoto>
      <Schedule />
      <ParallaxPhoto photoClass="parallax-arbanasi">
        <br />
        <h3 className="text-white text-center p-left-1 p-right-1">
          „Има само едно щастие в живота– да обичаш и да бъдеш обичан.“
        </h3>
        <br />
      </ParallaxPhoto>
      <KrajbaNaBulkata />
      <LqskovskiManastir />
      <GrajdanskiBrak />
      <Restaurant />
      <Godparents />
      <Transport />
      <Counter />
      <ParallaxPhoto photoClass="parallax-rilski-ezera">
        <br />
        <h1 id="history" className="text-white text-center">
          НАШАТА ИСТОРИЯ
        </h1>
        <br />
      </ParallaxPhoto>
      <History />
      <Questions />
      <ConfirmationContainer />

      <div className="p-top-12 bg-base p-bottom-6 text-center">
        <img
          className="our-image-footer"
          src={DidoAndGizi}
          alt="Gizi and Dido"
        ></img>
        <h5>с обич:</h5>
        <h3>Рози &amp; Мони</h3>
        <div className="container m-auto flex">
          <hr className="flex flex-justify-center col-12-xs col-12-sm col-8 hr-style m-top-2 m-bottom-2 m-left-auto m-right-auto" />
        </div>
        <div>Copyright ©2024 Сватбена покана</div>
        <div>
          Автор: Булката
          <i className="m-left-1 fa-solid fa-hand-holding-heart"></i>
        </div>
      </div>
    </div>
  );
}

export default Content;
