import React from "react";
import EngagedSectionSlider from "./EngagedSectionSlider";
import imageEngaged from "./images/plane.webp";

export default function RingsSection() {
  return (
    <div className="flex flex-align-center flex-justify-center m-top-4 m-bottom-4">
       <div className="col-12">
          <img
            alt="Engaged photo2"
            className="display-block m-auto"
            src={imageEngaged}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
      </div>
      <div className="col-12 col-5-xl">
        <div className="m-bottom-2 text-sm" style={{color: "#708090"}}>Разговор между Деси и Виктор в самолета за Италия, след повикване от Капитана на самолета: <i className="text-primary">"Моля, Десислава Бозева да се яви незабавно в предната част на самолета!"</i>{" "}:<br/>Tам, където Виктор я очаква...</div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-hover">
            Той:{" "}
          </div>
          <h4>Искам да бъдеш моя жена.
        </h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-hover">
            Той:{" "}
          </div>
          <h4>Нямам търпение да създадем семейство заедно...
        </h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-2">
          <div className="text-lg text-md-xs text-bold m-right-2 text-hover">
            Той:{" "}
          </div>
          <h4>Ще се омъжиш ли за мен? 
        </h4>
        </div>

        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-3 text-primary">
            Тя:{" "}
          </div>
          <h4>Да, да, да, да!</h4>
        </div>
        <div className="text-lg text-md-xs m-top-4">Стана ясно - ЩЕ СЕ ЖЕНИМ!</div>
        <div className="text-lg text-md-xs m-top-2">
        Ако искате да ни се чудите, да ни се радвате или просто да се повеселите с нас,
        {" "}<br className="hidden-xs hidden-sm" /><span className="text-bold text-primary">НИЕ</span>,{" "}
        Десислава и Виктор, Ви каним на  <span className="text-bold text-primary">12 юли 2024 г.</span>{" "} във Вила Екатерина на нашия специален сватбен ден!
          <br />
          <br />
          За нас ще бъде чест да присъствате!
          <br />
          <br />
          Молим да потвърдите вашето присъствие до{" "}
          <span className="text-hover text-bold">
            <br className="hidden-xs hidden-sm" />
            10 Юни, 2024г.
          </span>{" "}
          като попълните формуляра в края на тази страница.
        </div>
        <div className="m-top-2 m-bottom-2 m-top-4-sm m-bottom-no-sm m-top-4-md m-top-4-lg text-center-sm text-center-md text-center-lg">
          <a href="#confirm" className="confirm-btn full-width-xs">
            <i className="fa-solid fa-calendar-check m-right-1"></i>ПОТВЪРДИ{" "}
            <span className="hidden-xs">ПРИСЪСТВИЕ</span>
          </a>
        </div>
      </div>
      <div className="col-6 col-12-md col-12-lg col-5-xl p-left-2 p-left-no-xs flex flex-justify-center slider hidden-xs hidden-sm relative">
        <div className="engaged-sticky-slider">
          <EngagedSectionSlider width={420} height={600} />
          <h6 className="text-center m-top-1 col-12">05 Юни, 2023г.</h6>
        </div>
      </div>
    </div>
  );
}
