import React from "react";
import EngagedSectionSlider from "./EngagedSectionSlider";

export default function RingsSection() {
  return (
    <div className="flex flex-align-center bg-base m-top-4 m-bottom-4">
      <div className="col-12 col-6-xl">
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-secondary">
            Той:{" "}
          </div>
          <h4>Ще се ожен... омъжиш ли за мен?</h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-3 text-primary">
            Тя:{" "}
          </div>
          <h4>Шегуваш се!?</h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-secondary">
            Той:{" "}
          </div>
          <h4>Ха-ха, не...</h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-3 text-primary">
            Тя:{" "}
          </div>
          <h4>Разбира се!</h4>
        </div>
        <div className="text-lg text-md-xs m-top-4">
          Бъдете с нас в Арбанаси на 27 Август, 2022г., за да чуе{" "}
          <span className="text-bold text-primary">ТОЙ</span> заветното{" "}
          <span className="text-bold text-primary">"ДА"</span> и за да разбере{" "}
          <span className="text-bold text-primary">ТЯ</span>, че не е било шега!
          <br />
          <br />
          За нас ще бъде чест да присъствате.
          <br />
          <br />
          Молим да потвърдите вашето присъствие до{" "}
          <span className="text-hover text-bold">
            <br className="hidden-xs hidden-sm" />
            31 Юли, 2022г.
          </span>{" "}
          като попълните формуляра в края на тази страница.
        </div>
        <div className="m-top-2 m-bottom-2 text-center-md text-center-lg">
          <a href="#confirm" className="confirm-btn">
            <i className="fa-solid fa-calendar-check m-right-1"></i>ПОТВЪРДИ{" "}
            <span className="hidden-xs">ПРИСЪСТВИЕ</span>
          </a>
        </div>
      </div>
      <div className="col-6 col-12-md col-12-lg p-left-2 p-left-no-xs flex flex-justify-center slider hidden-xs hidden-sm">
        <EngagedSectionSlider width={420} height={600} />
        <h6 className="text-center m-top-1 col-12">29 Септември, 2021г.</h6>
      </div>
    </div>
  );
}
