import React, { useState } from "react";
import axios from "axios";
import "./ConfirmForm.scss";
import InputForm from "./InputForm";
import BulgarianRose from "./images/bulgarian-rose.webp";

function ConfirmForm() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [coming, setComing] = useState(true);
  const [menu, setMenu] = useState("Месно");
  const [allergic, setAllergic] = useState(false);
  const [dangerousFood, setDangerousFood] = useState("");

  const [loading, setLoading] = useState(false);

  function clearForm() {
    setLastName("");
    setName("");
    setEmail("");
  }

  async function submitForm(e) {
    e.preventDefault();

    if (!name || !lastName) {
      alert("Упс, май забрави да попълниш нещо?");
      return;
    }

    //TODO: TEST HERE
    const webhookUrl = "https://hook.eu2.make.com/9tbo54gfxxye1ub1kx1vgxy5c4bnb3o6";//"https://hooks.zapier.com/hooks/catch/3861449/baknc3g/";

    const data = {
      name: name,
      lastName: lastName,
      email: email,
      coming: coming,
      menu: coming ? menu : "",
      allergic: coming ? allergic : "",
      dangerousFood: coming ? dangerousFood : "",
    };

    try {
      setLoading(true);
      let res = await axios.post(webhookUrl, data);

      if (res.status === 200) {
        setLoading(false);
        setIsSent(true);

        if (window) {
          window.scrollTo(0, window.pageYOffset - 500);
        }
      }
    } catch (error) {
      setLoading(false);
      alert("Възникна грешка. Моля опитайте пак или се свържете с нас!");
      clearForm();
    }
  }

  if (isSent) {
    return (
      <section className="confirm-form-section">
        <div className="flex flex-justify-center">
          <div className="col-6 col-12-xs col-12-sm col-12-md col-10-lg">
            <div className="modern-form p-4 p-2-xs p-top-6 p-top-3-xs text-center">
              <div className="text-lg m-bottom-1">
                Благодарим за отделеното време.
              </div>
              {coming && (
                <div>
                  <span className="text-primary text-bold">{name}</span>,
                  успешно потвърди присъствието си на нашата сватба :){" "}
                </div>
              )}
              {!coming && (
                <div>
                  <span className="text-primary text-bold">{name}</span>, получи
                  черна точка ;/{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="flex flex-justify-center">
        <div className="col-6 col-12-xs col-12-sm col-12-md col-10-lg relative">
          <div className="bulgarian-rose-form">
            <img
              style={{ width: "100%" }}
              src={BulgarianRose}
              alt="Gizi and Dido"
            ></img>
          </div>
          <form
            className="modern-form p-4 p-2-xs p-top-6 p-top-3-xs"
            onSubmit={(e) => submitForm(e)}
          >
            <InputForm
              id="firstname"
              type="text"
              text="Име*"
              isRequired={true}
              onChange={setName}
            />

            <InputForm
              id="lastname"
              type="text"
              text="Фамилия*"
              isRequired={true}
              onChange={setLastName}
            />

            <InputForm
              id="email"
              type="email"
              text="Имейл"
              isRequired={false}
              onChange={setEmail}
            />
            <div className="text-lg m-bottom-1">Ще можеш ли да присъстваш?*</div>
            <ul className="text-lg p-2">
              <li>
                <input
                  type="radio"
                  value="true"
                  checked={coming}
                  onChange={() => setComing(true)}
                />
                <label>Да</label>
              </li>

              <li>
                <input
                  type="radio"
                  value="false"
                  checked={!coming}
                  onChange={() => setComing(false)}
                />
                <label>Не, съжалявам</label>
              </li>
            </ul>

            {coming && (
              <div>
                <div className="text-lg m-bottom-1">Избор на меню*</div>
                <ul className="text-lg p-2">
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="true"
                        checked={menu === "Месно"}
                        onChange={() => setMenu("Месно")}
                      />
                      Възрастен
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="false"
                        checked={menu === "Детско - Месно"}
                        onChange={() => setMenu("Детско - Месно")}
                      />
                      Детско
                    </label>
                  </li>
                </ul>
              </div>
            )}

            {coming && (
              <div>
                <div className="text-lg m-bottom-1">
                  Имаш ли алергия към дадена храна?
                </div>
                <ul className="text-lg p-2">
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="true"
                        checked={allergic}
                        onChange={() => setAllergic(true)}
                      />
                      Да, имам
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="false"
                        checked={!allergic}
                        onChange={() => setAllergic(false)}
                      />
                      Не
                    </label>
                  </li>
                </ul>
              </div>
            )}

            {coming && allergic && (
              <InputForm
                id="allergyFood"
                type="text"
                text="Коя е опасната храна?"
                isRequired={false}
                onChange={setDangerousFood}
              />
            )}

            <div className="m-top-4 m-bottom-2 text-center-xs">
              <button
                id="submit"
                type="submit"
                className="confirm-btn relative"
              >
                ИЗПРАТИ
                {loading && (
                  <div className="loading">
                    <div className="lds-roller">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ConfirmForm;
