import React from "react";
import KaloyanovaKrepost from "./images/kaloqnovakrepost.webp";

function Restaurant() {
  return (
    <div
      className="restaurant bg-secondary p-top-8 p-bottom-8"
      id="restaurant"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-brands fa-fort-awesome m-right-1 text-xl" />
            <span className="text-primary">19:00</span>
          </h2>
          <h1 className="m-bottom-1">Сватбено тържество</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={KaloyanovaKrepost}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1 m-top-2">
            И нека веселието продължи в ресторанта на <span className="text-bold">"Вила Екатерина"</span>, Вакарел.
            Вилата разполага с голям паркинг и такситата
            от София изпълняват курсове от/до ресторанта.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
