import React from "react";
import GrajdanskiBrakImage from "./images/garden.webp";
import RingsIcon from "./images/rings.webp";

function GrajdanskiBrak() {
  return (
    <div className="garden garden--bg p-top-8 p-bottom-8" id="grajdanskibrak">
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <img
              alt=""
              src={RingsIcon}
              style={{ width: 30 }}
              className="flex m-right-1 text-xl"
            />
            <span className="text-primary">17:00</span>
          </h2>
          <h1 className="m-bottom-1">Граждански брак</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={GrajdanskiBrakImage}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-top-2">
            Изнесеният ритуал ще се изпълни в градината на "Калоянова крепост",
            Арбанаси. Ще бъде съпроводен от DJ, коктейли, кафета и хапки за
            залъгване на глада. След ритуала, ще имаме време да се поздравим и
            снимаме с всички наши гости.{" "}
          </p>
          <p className="m-bottom-1 m-top-2">
            Вместо цветя <i className="fa-solid fa-leaf"></i>, бихме се радвали
            да получим вашата любима <br className="hidden-xs hidden-sm" />{" "}
            рецепта. <i className="fa-solid fa-kitchen-set"></i>
          </p>
        </div>
      </div>
    </div>
  );
}

export default GrajdanskiBrak;
