import React from "react";
import "./Schedule.scss";
import FadeInSection from "./FadeInSection";
import BebeBulka from "./images/bebe-rozi.webp";
import BebeMladojenec from "./images/moni-bebe.webp";
import RingsIcon from "./images/rings.webp";

function History() {
  return (
    <div className="bg-base">
      <div className="timeline p-top-8 p-bottom-8">
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <div>
                <img alt="" src={BebeMladojenec} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">16 Септември, 1993г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се бебе Мануел
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">виновници: Женя и Красимир</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <div>
                <img alt="" src={BebeBulka} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">12 Февруари, 1995г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се бебе Розалина
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">виновници: сем. Попкочеви</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">Август, 2013г.</span>
              </h2>
              <h3 className="m-bottom-1">Запознанство
              <i className="fa-solid fa-handshake m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">
                В една красива лятна нощ в град Варна, Рози и Мони се срещнаха за първи път, но не тогава се случиха нещата…
              </p>
              <p className="m-bottom-1">виновници: Най-голямата сестра на булката и майката на младоженеца<i className="fa-regular fa-face-laugh-beam m-left-0"></i></p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">14 Октомври 2017г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Официално заедно
                <i className="fa-solid fa-heart m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">В гр. Благоевград докато плаваха заедно в лодка, заобиколени от лебеди, съзряха блясъка в очите си и разбраха, че са открили любовта.</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">05 Юни, 2022г.</span>
              </h2>
              <h3 className="m-bottom-1 flex flex-align-center">
                <span>Тя каза "ДА"!</span>
              
                <div className="flex flex-align-center">
                  <img
                    alt=""
                    src={RingsIcon}
                    style={{ width: 30 }}
                    className="m-left-2"
                  />
                </div>
              </h3>
              <p className="m-bottom-1">
                Дългоочакваното предложение се случи в една лятна нощ в "Еко-хотел Момата", след като Булката беше станала кръстница на своята най-малка племенница - Селина.
              </p>
              <p className="m-bottom-1">виновник: Мони</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">07 Юли, 2024г.</span>
              </h2>
              <h3 className="m-bottom-1">Сватба - сем. Николови</h3>
              <p className="m-bottom-1">
              И нека продължим тази история заедно! Бъдете с нас, в Денят, в който сем. Попкочеви и сем. Николови ще се слеят в едно!
              </p>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default History;
