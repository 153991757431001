import React from "react";
import "./Schedule.scss";
import FadeInSection from "./FadeInSection";
import RingsIcon from "./images/rings.webp";
import DesiImage1 from "./images/desi1.webp";
import DesiImage2 from "./images/desi2.webp";
import ViktorImage1 from "./images/viktor1.webp";
import ViktorImage2 from "./images/viktor2.webp";

function History() {
  return (
    <div className="bg-base">
      <div className="timeline p-top-8 p-bottom-8">
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">27 Октомври, 1992г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Проплаква бебе Виктор
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <div>
                <img alt="" src={DesiImage1} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">20 Декември, 1995г.</span>
              </h2>
              <h3 className="m-bottom-1">Проплаква бебе Десислава<i className="fa-solid fa-baby m-left-2 text-xl hidden-xs"></i></h3>
            </div>
            <div className="timeline-circle"></div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <div>
                <img alt="" src={ViktorImage1} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">Лято, 1996г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Виктор на море в Китен 
              </h3>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <div>
                <img alt="" src={DesiImage2} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">20 Декември, 1999г.</span>
              </h2>
              <h3 className="m-bottom-1">Рожден ден на Деси</h3>
              <p className="m-bottom-1"> в детската г(р)адина<i className="fa-regular fa-face-laugh-beam m-left-0"></i></p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <div>
                <img alt="" src={ViktorImage2} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">Аквамен Виктор</span>
              </h2>
              <h3 className="m-bottom-1">
                Нетърпелив за плаж<i className="fa-regular fa-face-laugh-beam m-left-2"></i>
              </h3>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">Февруари, 2023г.</span>
              </h2>
              <h3 className="m-bottom-1">Запознанство
              <i className="fa-solid fa-handshake m-left-2 text-xl"></i>
              </h3>
              <p className="m-bottom-1">
              В един обикновен работен ден, Деси и Виктор се запознаха, но не осъзнаха, че този ден ще бъде тъй значим... и че случайни неща... няма!
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">05 Юни, 2023г.</span>
              </h2>
              <h3 className="m-bottom-1 flex flex-align-center">
                <span>Тя каза "ДА"!</span>
                <div className="flex flex-align-center">
                  <img
                    alt=""
                    src={RingsIcon}
                    style={{ width: 30 }}
                    className="m-left-2"
                  />
                </div>
              </h3>
              <p className="m-bottom-1">На Любовта от пръв поглед <i className="m-left-0 fa-regular fa-heart text-lg"></i></p>
              <p className="m-bottom-1">виновник: Виктор</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">12 Юли, 2024г.</span>
              </h2>
              <h3 className="m-bottom-1">Сватба - сем. Милушеви</h3>
              <p className="m-bottom-1">
                И нека продължим тази история заедно! Бъдете с нас в Деня, в
                който сем. Бозеви и сем. Милушеви ще се слеят в едно!{" "}
              </p>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default History;
