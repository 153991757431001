import React from "react";
import BulgarianRoses from "./images/bulgarian-roses.webp";

function Questions() {
  return (
    <div className="bg-page">
      <section className="container text-center p-top-8 p-bottom-8">
        <div className="flex flex-justify-center">
          <div className="col-5 col-8-xs">
            <img
              style={{ width: "100%" }}
              src={BulgarianRoses}
              alt="Bulgarian Roses"
            ></img>
          </div>
          <div className="col-12-xs col-12-sm col-8">
            <h2 className="m-bottom-2 text-primary">
              С кого да се свържа в деня на Сватбата?
            </h2>
            <div className="text-lg p-right-10-xl p-left-10-xl">
              При възникване на всякакъв тип въпроси в деня на сватбата се
              свържете с нашия сватбен агент:{" "}
            </div>
            <p className="m-top-2 m-bottom-2 text-lg m-bottom-8">
              Младенов, тел.{" 089 364 90003"}
              <span className="text-bold text-hover">088 74 84 218</span>
            </p>
            <h2 className="m-bottom-2 text-primary">Цветя и Подаръци</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-8">
              За подаръци и цветя не мислете, вашето настроение ни подарете, а
              при желание - вашата любима рецепта ни напишете и в пликче ни я
              поднесете.
            </div>
            <h2 className="m-bottom-2 text-primary">Нощувка</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-4">
              Съветваме ви да търсите нощувка във Велико Търново. Насреща сме да
              ви помогнем с резервацията при нужда. Можем да съдействаме и на
              гостите, останали без транспорт.
            </div>
            <div className="text-lg m-bottom-8">
              За допълнителни въпроси, свържете се с нас или ни пишете на{" "}
              <span className="text-bold text-hover">g.geneva94@gmail.com</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questions;
