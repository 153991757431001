import React from "react";
import KaloyanovaKrepost from "./images/kaloqnovakrepost.webp";

function Restaurant() {
  return (
    <div
      className="restaurant bg-primary p-top-8 p-bottom-8"
      id="restaurant"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-brands fa-fort-awesome m-right-1 text-xl text-secondary" />
            <span className="text-secondary">19:30</span>
          </h2>
          <h1 className="m-bottom-1 text-white">Сватбено тържество</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={KaloyanovaKrepost}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1 m-top-2 text-white">
            Веселбата ще продължи в <strong>Хотел-ресторант Хановете</strong> под откритото звездно небе (съветваме ви да си вземете нещо връхно). Комплексът разполага с голям паркинг. Такситата от Петрич ще изпълняват курсове от/до ресторанта в случай, че сте отседнали на друго място.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
