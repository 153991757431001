import React from "react";
import KrajbaBulka from "./images/kradenenabulka.webp";
import TheCarImage from "./images/thecar.webp";


function KrajbaNaBulkata() {
  return (
    <div
      className="bride-steal bride-steal--bg p-top-8 p-bottom-8"
      id="bridesteal"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-solid fa-house-lock m-right-1 text-xl" />
            <span className="text-third">13:00</span>
          </h2>
          <h1 className="m-bottom-1">Кражба на Булката</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto p-bottom-4"
            src={KrajbaBulka}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1">
          Булката ще се крие в родната си къща в <span className="text-third text-bold">с. Коларово</span>, където ще бъде строго охранявана от своите сестри, зетьове и роднини. Младоженецът, с неговата чета от приятели и роднини ще се съберат в  <span className="text-third text-bold">12:50ч.</span> в двора на  <span className="text-third text-bold">Еко Хотел “Момата” с.Коларово</span>, откъдето под ритъма на тъпаните и зурните ще се запътят към къщата на булката.
          </p>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h4 className="m-bottom-1 m-top-3">Не изпускайте да видите също семейната реликва <i className="fa-solid fa-car m-left-1"></i></h4>
          <div>
            <img
              alt="Krajba na Bulka"
              className="display-block m-auto p-bottom-4"
              src={TheCarImage}
              style={{ width: "100%", maxWidth: 600 }}
            ></img>
          </div>
          <h4 className="m-bottom-1">Москвича на Тъста!</h4>
          <p className="m-bottom-1">След като е изиграл важна роля в ергенските години на Тъста, спомогнал да свали Тъщата и извозил двете дъщери до олтара, идва ред и на най-малката сестра да бъде изпратена с него!</p>
        </div>
      </div>
    </div>
  );
}

export default KrajbaNaBulkata;
