import React from "react";
import EngagedSectionSlider from "./EngagedSectionSlider";

export default function RingsSection() {
  return (
    <div id="intro-invitation" className="flex flex-align-center m-top-4 m-bottom-4">
      <div className="col-12 col-6-xl p-left-2-md p-left-2-lg">
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-primary">
            Той:{" "}
          </div>
          <h4>Душице, ще се омъжиш ли за мен?</h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-3 text-white">
            Тя:{" "}
          </div>
          <h4>Да, Любовче!</h4>
        </div>
        <div className="text-lg text-md-xs m-top-4">
          Бъдете наши специални гости в гр. Петрич на 07 Юли 2024г., за да чуе{" "}
          <span className="text-bold text-primary">ТОЙ</span> заветното{" "}
          <span className="text-bold text-primary">"ДА"</span>, a {" "}
          <span className="text-bold text-white">ТЯ</span>{" "}да бъде най-щастливата жена!
          <br />
          <br />
          За нас ще бъде чест да присъствате.
          <br />
          <br />
          Молим да потвърдите вашето присъствие до{" "}
          <span className="text-base text-bold">
            <br className="hidden-xs hidden-sm" />
            20 Юни, 2024г.
          </span>{" "}
          като попълните формуляра в края на тази страница.
        </div>
        <div className="m-top-2 m-bottom-2 text-center-md text-center-lg">
          <a href="#confirm" className="confirm-btn">
            <i className="fa-solid fa-calendar-check m-right-1"></i>ПОТВЪРДИ{" "}
            <span className="hidden-xs">ПРИСЪСТВИЕ</span>
          </a>
        </div>
      </div>
      <div className="col-6 col-12-md col-12-lg p-left-2 p-left-no-xs flex flex-justify-center slider hidden-xs hidden-sm">
      <EngagedSectionSlider width={420} height={600} />
        <h6 className="text-center m-top-1 col-12">Годеж - 05 Юни, 2022г.</h6>
      </div>
    </div>
  );
}
