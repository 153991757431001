import React from "react";
import FoldBox from "./FoldBox";
import "./Fold.scss";

function Fold() {
  return (
    <div>
      <div className="relative container fold p-top-12-xs p-top-12-sm">
        <div className="font-heading text-lg text-right m-right-10 m-top-3 text-fold hidden-xs hidden-sm hidden-md">
          "Посрещаме новото утро, <br />
          за да тръгнем по общия път, <br />
          защото когато сме двама, <br />
          по-светъл и лек е денят <br />и по-уютен по залез домът... "
        </div>
        <FoldBox />
        <h4 className="font-heading text-center p-bottom-4 m-top-4">
          С благословията на нашите скъпи родители, <br className="hidden-xs" />
          с радост Ви каним да споделите с нас Денят,{" "}
          <br className="hidden-xs" />в който ще се наречем{" "}
          <span className="text-primary">Съпруг и Съпруга</span>!
        </h4>
        <h1 className="text-center p-bottom-4">27 Август 2022г.</h1>
      </div>
      <div className="flex">
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#F0EBEC" }}
        ></div>
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#452B39" }}
        ></div>
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#A24F5C" }}
        ></div>
        <div
          className="col-3 p-top-1 p-bottom-1"
          style={{ backgroundColor: "#C48B67" }}
        ></div>
      </div>
    </div>
  );
}

export default Fold;
